import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  Drawer,
  Grid,
  Typography,
  IconButton,
  Box,
} from '@mui/material';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutlined';
import EditOutlineIcon from '@mui/icons-material/EditOutlined';
import {
  DataGrid,
} from '@mui/x-data-grid';
import { CoreButton, Hooks, TuiSpinner } from '../../../core';
import { tiffinPaymentApi } from '../../../js/slices/api_slices';
import { TIFFIN } from '../../../js/lib/constants';
import CustomerPaymentForm from './customer_payment_form';

const { useSnackBarNotification } = Hooks;
const { useGetTiffinPaymentsQuery, useDeleteTiffinPaymentMutation } = tiffinPaymentApi;

export default function CustomerPaymentDrawer({ open, handleClose, item, fetchSubscriber }) {
  const { errorSnackBar, successSnackBar } = useSnackBarNotification();
  const [showPaymentForm, setShowPaymentForm] = useState(false);
  const [action, setAction] = useState('add');
  const [rows, setRows] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);

  const { data: tiffinPayments,
    isSuccess: tiffinPaymentsFetchSuccess,
    isLoading: tiffinPaymentsFetchLoading } = useGetTiffinPaymentsQuery({
      tiffinSubscriberId: item?.id,
      page: 1,
      pageSize: 20,
      sortKey: '-startDate',
    }, { refetchOnMountOrArgChange: true, skip: !(item?.id && open) });

  useEffect(() => {
    if (tiffinPaymentsFetchSuccess) {
      setRows(tiffinPayments?.data?.items?.map((item, index) => {
        return {
          id: item?.id,
          startDate: moment(item?.startDate).format('DD MMM YYYY'),
          endDate: moment(item?.endDate).format('DD MMM YYYY'),
          status: item?.status,
          amount: item?.amount,
          referenceId: item?.referenceId,
          expectedPaymentDate: item?.expectedPaymentDate ? moment(item?.expectedPaymentDate).format('DD MMM YYYY') : '--',
          index,
        };
      }));
    }
  }, [tiffinPayments, tiffinPaymentsFetchSuccess]);

  const [deleteTiffinPayment, {
    data: deleteTiffinPaymentData,
    isSuccess: deleteTiffinPaymentSuccess,
    error: deleteTiffinPaymentError,
    isError: deleteTiffinPaymentIsError,
    isLoading: deleteTiffinPaymentIsLoading,
  }] = useDeleteTiffinPaymentMutation();

  useEffect(() => {
    if (deleteTiffinPaymentSuccess && deleteTiffinPaymentData) {
      successSnackBar({ message: 'Payment deleted successfully' });
      const deletePayment = deleteTiffinPaymentData.data;
      const updatedRows = rows.filter((row) => row.id !== deletePayment?.id);
      setRows(updatedRows.map((item, index) => {
        return {
          ...item,
          index,
        };
      }));
      fetchSubscriber({ id: deletePayment?.tiffinSubscriberId });
    } if (deleteTiffinPaymentIsError && deleteTiffinPaymentError) {
      errorSnackBar({ message: deleteTiffinPaymentError?.data?.errorMessage });
    }
  }, [deleteTiffinPaymentSuccess, deleteTiffinPaymentIsError, deleteTiffinPaymentData, deleteTiffinPaymentError]);

  const columns = [
    { field: 'startDate', headerName: 'Start Date', width: 120, editable: false },
    { field: 'endDate', headerName: 'End Date', width: 120, editable: false },
    { field: 'amount', headerName: 'Amount', width: 110, editable: false },
    { field: 'referenceId', headerName: 'Reference Id', width: 110, editable: false },
    { field: 'status', headerName: 'Status', width: 110, editable: false },
    { field: 'expectedPaymentDate', headerName: 'Expected Date', width: 110, editable: false },
    {
      field: 'action',
      type: 'actions',
      headerName: 'Actions',
      width: 130,
      cellClassName: 'actions',
      getActions: (data) => {
        return data?.row?.index === 0 && rows.length > 1 && item?.status !== TIFFIN.STATUS.CANCEL ?
          [
            deleteTiffinPaymentIsLoading ? <TuiSpinner /> : <IconButton
              key={data?.id}
              edge="end"
              aria-label="delete"
              onClick={() => {
                setAction('add');
                deleteTiffinPayment({ id: data?.id })
              }}>
              <DeleteOutlineIcon />
            </IconButton>,
            <IconButton
              key={data?.id}
              edge="end"
              aria-label="update"
              onClick={() => {
                setAction('update');
                setSelectedRow(data?.row);
                setShowPaymentForm(true)
              }
              }>
              <EditOutlineIcon />
            </IconButton>,
          ] : [];
      },
    },
  ];

  return (
    <>
      <Drawer
        anchor="right"
        PaperProps={{
          sx: { width: 850 },
        }}
        open={open}
        onClose={handleClose}>
        <Grid container sx={{ p: 2 }} spacing={1}>
          <Grid item container xs={12}>
            <Grid item xs={1}>
              <IconButton
                onClick={handleClose}
                sx={{ p: 0, pr: 1, pt: 0.5 }}
              >
                <ArrowBackIcon />
              </IconButton>
            </Grid>
            <Grid item xs={1}>
              <Typography variant="h6" fontWeight='bold'>Payments</Typography>
            </Grid>
            <Grid item xs={10} justifyContent='flex-end' container>
              <CoreButton
                variant='contained'
                fullWidth={false}
                type="submit"
                startIcon={<AddOutlinedIcon />}
                disabled={item?.status === TIFFIN.STATUS.CANCEL}
                onClickHandler={() => {
                  setAction('add');
                  setShowPaymentForm(true);
                }}
              >Add
              </CoreButton>
            </Grid>
          </Grid>
          {tiffinPaymentsFetchLoading && <TuiSpinner /> }
          {!tiffinPaymentsFetchLoading && <Grid item xs={12}>
            <Box
              sx={{
                'height': '80vh',
                'width': '100%',
                '& .actions': {
                  color: 'text.secondary',
                },
                '& .textPrimary': {
                  color: 'text.primary',
                },
              }}
            >
              <DataGrid
                rows={rows}
                columns={columns}
                editMode="row"
                initialState={{
                  pagination: { paginationModel: { pageSize: 5 } },
                }}
              />
            </Box>
          </Grid>}
        </Grid>
      </Drawer>
      <CustomerPaymentForm
        open={showPaymentForm}
        action={action}
        item={action === 'update' ? selectedRow : item}
        rows={rows}
        setRows={setRows}
        fetchSubscriber={fetchSubscriber}
        handleClose={() => {
          setShowPaymentForm(false);
          setSelectedRow(null);
          setAction(null);
        }} />
    </>
  );
}

CustomerPaymentDrawer.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  viewOnly: PropTypes.bool,
  item: PropTypes.object,
  fetchSubscriber: PropTypes.func,
};