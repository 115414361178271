import React, { useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  TableContainer,
  Paper,
  TableSortLabel,
  Checkbox,
} from '@mui/material';
import PropTypes from 'prop-types';

import TuiSpinner from './base_spinner';

export default function TuiTable({ headers, data = [], isLoading, selectedRow, setSelectedRow, selectAllChecked, selectAllHandler, sort = false }) {
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('');

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const sortedData = React.useMemo(() => {
    if (!sort || !orderBy) return data;
    return data.slice().sort((a, b) => {
      if (a[orderBy] < b[orderBy]) return order === 'asc' ? -1 : 1;
      if (a[orderBy] > b[orderBy]) return order === 'asc' ? 1 : -1;
      return 0;
    });
  }, [data, order, orderBy, sort]);

  const tableHeaders = (
    <TableHead sx={{ backgroundColor: '#eeeeee' }}>
      <TableRow>
        {headers.map(({ title, id, numeric = false, disablePadding = false }) => {
          if (id === 'checkbox') {
            return (
              <TableCell
                key={id}
                align={numeric ? 'right' : 'left'}
                padding={disablePadding ? 'none' : 'normal'}
              >
                <Checkbox
                  size='small'
                  checked={selectAllChecked}
                  onClick={selectAllHandler}
                />
              </TableCell>
            );
          }
          return (
            <TableCell
              key={id}
              sx={{ fontWeight: 'bold', fontSize: 16 }}
              align={numeric ? 'right' : 'left'}
              padding={disablePadding ? 'none' : 'normal'}
              sortDirection={sort && orderBy === id ? order : false}
            >
              {sort ? (
                <TableSortLabel
                  active={orderBy === id}
                  direction={orderBy === id ? order : 'asc'}
                  onClick={() => handleRequestSort(id)}
                >
                  {title}
                </TableSortLabel>
              ) : (
                title
              )}
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );

  const getTableCells = (valuesArr) => {
    return valuesArr.map((item, i) => (
      <TableCell key={i}>
        {item}
      </TableCell>
    ));
  };

  return (
    <TableContainer component={Paper}>
      <Table>
        {tableHeaders}
        <TableBody>
          {isLoading && <TuiSpinner />}
          {sortedData.map((item) => {
            const newItem = { ...item };
            delete item?.id;
            delete item?.secondaryId;
            const backgroundColor = item?.backgroundColor || '#ffffff';
            delete item?.backgroundColor;
            const allValues = Object.values(item);
            return (
              <TableRow
                style={{
                  cursor: 'pointer',
                  transition: 'background-color 0.3s ease', // Smooth transition for hover effect
                  backgroundColor: backgroundColor,
                }}
                onMouseEnter={(e) => !selectedRow && (e.currentTarget.style.backgroundColor = '#f0f0f0')}
                onMouseLeave={(e) => e.currentTarget.style.backgroundColor = backgroundColor}
                key={newItem.id || Math.random()}
                onClick={(e) => {
                  e.preventDefault();
                  setSelectedRow(newItem);
                }}
                tabIndex={-1}
              >
                {getTableCells(allValues)}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

TuiTable.propTypes = {
  isLoading: PropTypes.bool,
  data: PropTypes.array,
  headers: PropTypes.array.isRequired,
  sort: PropTypes.bool,
};