import React from 'react';
import moment from 'moment-timezone';
import { Chip, Tooltip } from '@mui/material';
import { TIFFIN } from '../../js/lib/constants';
import { getFormattedDateFromUnixTimestamp, getTimezone } from '../../js/lib/utils';

export const getStatusChip = (item) => {
  switch (item?.status) {
    case TIFFIN.STATUS.NOT_STARTED:
      return <Chip label={TIFFIN.STATUS.NOT_STARTED} sx={{ backgroundColor: '#2196f3', color: 'white' }}/>;
    case TIFFIN.STATUS.ACTIVE:
      return <Chip label='Active' sx={{ backgroundColor: '#4CAF50', color: 'white' }}/>;
    case TIFFIN.STATUS.EXPIRING_SOON:
      return <Chip label='Expiring' sx={{ backgroundColor: '#FF8C00', color: 'white' }}/>;
    case TIFFIN.STATUS.EXPIRED:
      return <Chip label='Expired' sx={{ backgroundColor: '#E31A1C', color: 'white' }}/>;
    case TIFFIN.STATUS.HOLD:
      return <Chip label='Hold' sx={{ backgroundColor: '#008B8B', color: 'white' }}/>;
    case TIFFIN.STATUS.CANCEL:
      return <Chip label='Cancelled' sx={{ backgroundColor: '#616161', color: 'white' }}/>;
    default:
      return null;
  }
};

export const getPaymentStatusChip = (item) => {
  switch (item?.paymentStatus) {
    case TIFFIN.PAYMENTS.PAID:
      return <Chip label={TIFFIN.PAYMENTS.PAID} sx={{ backgroundColor: '#238443', color: 'white' }} size='small'/>;
    case TIFFIN.PAYMENTS.PENDING:
      return <Tooltip arrow placement="top" title={item?.expectedPaymentDate ? `Expected payment date ${getFormattedDateFromUnixTimestamp(item?.expectedPaymentDate)}` : ''}>
        <Chip label={`Payment ${TIFFIN.PAYMENTS.PENDING}`} sx={{ backgroundColor: '#EE8D22', color: 'white' }} size='small'/>
        </Tooltip>;
  }
};

export const getRemainingDays = (item, timezone) => {
  let extractedTimezone = getTimezone(timezone);
  const momentEndDate = moment(item?.endDate).tz(extractedTimezone).endOf('day');
  const currentDate = moment().tz(extractedTimezone).startOf('day');
  const diff = momentEndDate.diff(currentDate, 'days');
  return diff;
};

export const getStatusLabel = (item, timezone) => {
  if (item?.status === TIFFIN.STATUS.CANCEL) {
    return '';
  } else {
    const startDate = moment(item?.startDate).tz(getTimezone(timezone)).startOf('day');
    const currentDate = moment().tz(getTimezone(timezone)).startOf('day');
    if (startDate.isAfter(currentDate)) {
      const daysToGo = startDate.diff(currentDate, 'days');
      return `${daysToGo} day/s to go`;
    } else {
      const remainingDays = getRemainingDays(item, timezone);
      return remainingDays >= 0 ? `${remainingDays} days left` : `${Math.abs(remainingDays)} days passed`;
    }
  }
};