import React, { useEffect, useState } from 'react';
import {
  Grid2 as Grid,
  Tooltip,
  Menu,
  MenuItem,
  ListItemIcon,
  Divider,
  Checkbox,
  Typography,
  Chip
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import DeliveryDiningIcon from '@mui/icons-material/DeliveryDining';
import HailIcon from '@mui/icons-material/Hail';
import MessageIcon from '@mui/icons-material/Message';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import EditIconOutlined from '@mui/icons-material/EditOutlined';
import PauseCircleOutlineOutlinedIcon from '@mui/icons-material/PauseCircleOutlineOutlined';
import HistoryOutlinedIcon from '@mui/icons-material/HistoryOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import AttachMoneyOutlinedIcon from '@mui/icons-material/AttachMoneyOutlined';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import TocIconOutlined from '@mui/icons-material/TocOutlined';
import StarsIcon from '@mui/icons-material/Stars';
import RestartAltOutlinedIcon from '@mui/icons-material/RestartAltOutlined';

import { useNavigate } from 'react-router-dom';

import { Table, ConfirmationModal, Hooks } from '../../../core';
import CustomerViewModal from './customer_view_modal';
import { getStatusChip, getPaymentStatusChip } from '../customer_utils';
import PaymentDrawer from './customer_payment_drawer';
import CustomerHoldDrawer from './customer_hold_drawer';
import CustomerHistoryDrawer from './customer_history_drawer';
import { tiffinsApi } from '../../../js/slices/api_slices';
import { DELIVERY_METHOD, TIFFIN, STORAGE_KEYS } from '../../../js/lib/constants';
import CustomerTiffinGoodsDrawer from './customer_tiffin_goods_drawer';
import { getFormattedPhoneNumber, getFormattedDateFromUnixTimestamp } from '../../../js/lib/utils';

const headCells = [{
  id: 'checkbox',
  title: 'Select',
}, {
  id: 'name',
  title: 'Name',
}, {
  id: 'status',
  title: 'Status',
}, {
  id: 'phoneNumber',
  title: 'Phone Number',
}, {
  id: 'address',
  title: 'Address',
}, {
  id: 'tiffin',
  title: 'Tiffin',
}, {
  id: 'action',
  title: 'Actions',
}];

const { useLazyGetTiffinSubscriberQuery, useUpdateTiffinSubscriberMutation, useDeleteTiffinSubscriberMutation } = tiffinsApi;
const { useSnackBarNotification } = Hooks;

export default function CustomerListComponent({
  refetchCustomerStats,
  rows,
  setRows,
  isLoading,
  selectedItems,
  setSelectedItems,
  selectAllChecked,
  selectAllHandler,
  page,
  setPage,
  customerCreateLimitLeft
}) {
  const navigate = useNavigate();
  const [showTiffinViewModal, setShowTiffinViewModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [showPaymentDrawer, setShowPaymentDrawer] = useState(false);
  const [showChangesHistoryDrawer, setShowChangesHistoryDrawer] = useState(false);
  const [showHoldConfirmationModal, setShowHoldConfirmationModal] = useState(false);
  const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState(false);
  const [showResetConfirmationModal, setShowResetConfirmationModal] = useState(false);
  const [showCancelConfirmationModal, setShowCancelConfirmationModal] = useState(false);
  const [showTiffinItemsDrawer, setShowTiffinItemsDrawer] = useState(false);
  const { errorSnackBar, successSnackBar } = useSnackBarNotification();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [trigger, { data, isSuccess }] = useLazyGetTiffinSubscriberQuery();

  const [deleteCustomer, {
    data: deleteCustomerData,
    isSuccess: deleteCustomerIsSuccess,
    isError: deleteCustomerIsError,
    error: deleteCustomerError,
    isLoading: deleteCustomerIsLoading,
  }] = useDeleteTiffinSubscriberMutation();

  const [updateCustomer, {
    data: updateCustomerData,
    isSuccess: updateCustomerIsSuccess,
    isError: updateCustomerIsError,
    error: updateCustomerError,
    isLoading: updateCustomerIsLoading,
  }] = useUpdateTiffinSubscriberMutation();

  useEffect(() => {
    if (deleteCustomerIsSuccess && deleteCustomerData) {
      successSnackBar({ message: 'Customers deleted successfully' });
      const newRows = rows.filter((row) => row?.id !== deleteCustomerData?.data?.id);
      if (!newRows.length && Number(page) > 1) {
        setPage(page - 1);
      }
      setRows(newRows);
    } if (deleteCustomerIsError && deleteCustomerError) {
      errorSnackBar({ message: deleteCustomerError?.data?.errorMessage });
    }
  }, [deleteCustomerIsSuccess, deleteCustomerIsError, deleteCustomerError]);

  useEffect(() => {
    if (updateCustomerIsSuccess && updateCustomerData) {
      successSnackBar({ message: 'Customers updated successfully' });
      setRows(rows.map((row) => row?.id === updateCustomerData?.data?.id ? updateCustomerData?.data : row));
      refetchCustomerStats();
    } if (updateCustomerIsError && updateCustomerError) {
      errorSnackBar({ message: updateCustomerError?.data?.errorMessage });
    }
  }, [updateCustomerIsSuccess, updateCustomerIsError, updateCustomerError]);

  useEffect(() => {
    if (isSuccess && data) {
      setRows(rows?.map(row => row.id === data?.data?.id ? data?.data : row));
    }
  }, [trigger, data, isSuccess]);

  const menuOptions = (item) => [{
    id: `${item?.id}_edit`,
    title: 'Edit',
    icon: <EditIconOutlined fontSize="small" />,
    disabled: selectedItem?.status === TIFFIN.STATUS.CANCEL,
    action: () => {
      navigate(selectedItem?.id);
    }
  }, {
    id: `${item?.id}_duplicate`,
    title: 'Duplicate',
    icon: <ContentCopyOutlinedIcon fontSize="small" />,
    disabled: false,
    action: () => {
      if (customerCreateLimitLeft <= 0) {
        errorSnackBar({ message: 'You have reached the maximum number of customers allowed by your plan. Please upgrade your plan to add more customers.' });
        return;
      }
      const copiedItem = { ...selectedItem };
      delete copiedItem?.id;
      delete copiedItem?.createdAt;
      delete copiedItem?.createdBy;
      delete copiedItem?.modifiedAt;
      delete copiedItem?.modifiedBy;
      sessionStorage.setItem(STORAGE_KEYS.CUSTOMER, JSON.stringify(copiedItem));
      navigate('/customers/new');
    }
  }, {
    id: `${item?.id}_cancel`,
    title: selectedItem?.status === TIFFIN.STATUS.CANCEL ? 'Reactivate' : 'Cancel',
    icon: selectedItem?.status === TIFFIN.STATUS.CANCEL ? <RestartAltOutlinedIcon fontSize="small" /> : <CancelOutlinedIcon fontSize="small" />,
    action: () => {
      if (selectedItem?.status === TIFFIN.STATUS.CANCEL) {
        setShowResetConfirmationModal(true);
      } else {
        setShowCancelConfirmationModal(true);
      }
    }
  }, {
    id: `${item?.id}_delete`,
    title: 'Delete',
    icon: <DeleteOutlineOutlinedIcon fontSize="small" />,
    disabled: false,
    action: () => {
      setShowDeleteConfirmationModal(true);
    }
  }, {
    id: `${item?.id}_tiffin_items`,
    title: 'Tiffin Items',
    icon: <TocIconOutlined fontSize="small" />,
    disabled: selectedItem?.status === TIFFIN.STATUS.CANCEL,
    action: () => {
      setShowTiffinItemsDrawer(true);
    }
  }, {
    id: `${item?.id}_hold`,
    title: 'Hold',
    icon: <PauseCircleOutlineOutlinedIcon fontSize="small" />,
    disabled: selectedItem?.status === TIFFIN.STATUS.CANCEL,
    action: () => {
      setShowHoldConfirmationModal(true);
    }
  }, {
    id: `${item?.id}_payments`,
    title: 'Payments',
    icon: <AttachMoneyOutlinedIcon fontSize="small" />,
    disabled: selectedItem?.status === TIFFIN.STATUS.CANCEL,
    action: () => {
      setShowPaymentDrawer(true);
    }
  }, {
    id: `${item?.id}_history`,
    title: 'Change History',
    icon: <HistoryOutlinedIcon fontSize="small" />,
    disabled: selectedItem?.status === TIFFIN.STATUS.CANCEL,
    action: () => {
      setShowChangesHistoryDrawer(true);
    }
  }];

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    event.stopPropagation();
  };

  const handleClose = (e) => {
    setAnchorEl(null);
    e.stopPropagation();
  };

  const processedItems = rows.map((item) => {
    const { id, customer, shipping, deliveryInstructions } = item;

    const result = {
      checkbox: <Checkbox
        size='small'
        checked={selectedItems?.includes(item?.id)}
        onClick={(event) => {
          if (event.target.checked) {
            setSelectedItems([...selectedItems, item?.id])
          } else {
            setSelectedItems(selectedItems.filter((selectedItem) => selectedItem !== item?.id))
          }
          event.stopPropagation();
        }} />,
      name: <>
        <Typography>{`${customer?.firstName} ${customer?.lastName}`}
          {item?.adminNotes && (
            <Tooltip
              title={
                <React.Fragment>
                  <Typography variant="h7" color="inherit">Admin Notes</Typography>
                  <Typography variant="body2" sx={{ whiteSpace: 'pre-line', maxWidth: '200px', wordWrap: 'break-word' }}>
                    {item?.adminNotes}
                  </Typography>
                </React.Fragment>
              }
              placement="top"
            >
              <MessageIcon sx={{ fontSize: 16 }} />
            </Tooltip>
          )}
        </Typography>
        {item?.paymentStatus === TIFFIN.PAYMENTS.PENDING && getPaymentStatusChip(item)}
        {item?.holdStartDate > Date.now() &&
          <Tooltip title={`Hold start date ${getFormattedDateFromUnixTimestamp(item?.holdStartDate)}`} placement="top">
            <Chip label='Upcoming Hold' sx={{ backgroundColor: '#008B8B', color: 'white' }} size="small" />
          </Tooltip>
        }
      </>,
      status: getStatusChip(item),
      phoneNumber: getFormattedPhoneNumber(customer?.phoneNumber?.number),
      address: (
        <>
          <Tooltip title={deliveryInstructions} placement="left">
            {shipping === DELIVERY_METHOD.PICK_UP ? (
              <HailIcon sx={{ fontSize: 22, mr: 1, border: '1px solid black', borderRadius: '10%' }} />
            ) : (
              <DeliveryDiningIcon sx={{ fontSize: 22, mr: 1, border: '1px solid black', borderRadius: '10%' }} />
            )}
          </Tooltip>
          {customer?.unit ? `${customer?.unit} - ` : ''}
          {customer?.address?.description}
        </>
      ),
      tiffin: <>
        {item?.tiffin?.name}
        {item?.itemsChanged && <Tooltip title={'Special tiffin - Items customized'} placement="top">
          <StarsIcon sx={{ fontSize: 14, color: 'orange' }} />
        </Tooltip>}
      </>,
      action: <div>
        <LoadingButton
          loading={selectedItem?.id === item?.id && (updateCustomerIsLoading || deleteCustomerIsLoading)}
          aria-label={`customer-long-more-button-${id}`}
          id={`customer-long-button-${id}`}
          aria-controls={open ? `customer-long-button-${id}` : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-haspopup="true"
          onClick={(event) => {
            setSelectedItem(item);
            handleClick(event);
            event.stopPropagation();
          }}
        >
          <MoreVertIcon />
        </LoadingButton>
        {selectedItem?.id === item?.id && (
          <Menu
            id={`customer-long-menu-${id}`}
            MenuListProps={{
              'aria-labelledby': `customer-long-menu-${id}`,
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={(e) => {
              handleClose(e);
              setSelectedItem(null);
            }}
          >
            {menuOptions(item).map(({ id, title, icon, action, disabled }) => [
              <MenuItem
                disabled={disabled}
                key={id}
                onClick={(e) => {
                  action();
                  handleClose(e);
                }}>
                <ListItemIcon>
                  {icon}
                </ListItemIcon>
                {title}
              </MenuItem>,
              (title === "Delete") && <Divider key={`divider-${id}`} />
            ])}
          </Menu>
        )}
      </div>,
      id,
    };

    return result;
  });

  const deleteConfirmationModal = <ConfirmationModal
    open={showDeleteConfirmationModal}
    handleClose={() => {
      setShowDeleteConfirmationModal(false);
      setSelectedItem(null);
    }}
    title={<Grid container spacing={2}>
      <Grid size={12}>
        <Typography variant='h6'>{`Are you sure want to delete this customer?`}</Typography>
      </Grid>
    </Grid>}
    type="Delete"
    actionHandler={() => {
      deleteCustomer({ id: selectedItem?.id });
      setShowDeleteConfirmationModal(false);
      setSelectedItem(null);
    }}
  />;

  const resetConfirmationModal = <ConfirmationModal
    open={showResetConfirmationModal}
    handleClose={() => {
      setShowResetConfirmationModal(false);
      setSelectedItem(null);
    }}
    title={<Grid container spacing={2}>
      <Grid size={12}>
        <Typography variant='h6'>{`Are you sure want to reset the subscription of this customer?`}</Typography>
      </Grid>
    </Grid>}
    type="Update"
    actionHandler={() => {
      setShowResetConfirmationModal(false);
      updateCustomer({ id: selectedItem?.id, data: { ...selectedItem, status: TIFFIN.STATUS.ACTIVE } });
      setSelectedItem(null);
    }}
  />;

  const cancelConfirmationModal = <ConfirmationModal
    open={showCancelConfirmationModal}
    handleClose={() => {
      setShowCancelConfirmationModal(false);
      setSelectedItem(null);
    }}
    title={<Grid container spacing={2}>
      <Grid size={12}>
        <Typography variant='h6'>{`Are you sure want to cancel the subscription of this customer?`}</Typography>
      </Grid>
    </Grid>}
    type="Update"
    actionHandler={() => {
      setShowCancelConfirmationModal(false);
      updateCustomer({ id: selectedItem?.id, data: { ...selectedItem, status: TIFFIN.STATUS.CANCEL } });
      setSelectedItem(null);
    }}
  />;

  return (
    <>
      <Grid container>
        <Table
          selectAllHandler={selectAllHandler}
          selectAllChecked={selectAllChecked}
          headers={headCells}
          data={processedItems}
          isLoading={isLoading}
          selectedRow={selectedItem}
          setSelectedRow={(row) => {
            setSelectedItem(row);
            setShowTiffinViewModal(true);
          }} />
      </Grid>
      {showPaymentDrawer && <PaymentDrawer
        fetchSubscriber={trigger}
        open={showPaymentDrawer}
        viewOnly={false}
        item={selectedItem}
        handleClose={() => {
          setSelectedItem(null);
          setShowPaymentDrawer(false);
        }}
      />}
      {showHoldConfirmationModal && <CustomerHoldDrawer
        fetchCustomer={trigger}
        open={showHoldConfirmationModal}
        viewOnly={false}
        item={selectedItem}
        handleClose={() => {
          setSelectedItem(null);
          setShowHoldConfirmationModal(false);
        }}
      />}
      {showTiffinViewModal && <CustomerViewModal
        open={showTiffinViewModal}
        handleClose={() => {
          setSelectedItem(null);
          setShowTiffinViewModal(false);
        }}
        item={selectedItem} />}
      {showTiffinItemsDrawer && <CustomerTiffinGoodsDrawer
        item={selectedItem}
        open={showTiffinItemsDrawer}
        handleClose={() => {
          setSelectedItem(null);
          setShowTiffinItemsDrawer(false);
        }}
      />}
      {showChangesHistoryDrawer && <CustomerHistoryDrawer
        open={showChangesHistoryDrawer}
        handleClose={() => {
          setSelectedItem(null);
          setShowChangesHistoryDrawer(false);
        }}
        item={selectedItem}
      />}
      {showResetConfirmationModal && resetConfirmationModal}
      {showDeleteConfirmationModal && deleteConfirmationModal}
      {showCancelConfirmationModal && cancelConfirmationModal}
    </>
  );
}
